import { api } from "@/services/api";
import { useDeviceStore } from "@/stores/device.store";
import * as Sentry from "@sentry/vue";
import { AxiosError } from "axios";

export class PingService {
    private static instance: PingService;
    private timeout: number | undefined;
    private static intervalMilliseconds = 60 * 1000;

    private constructor() {
        if (PingService.instance) {
            throw new Error("Use PreferencesService.getInstance() instead of new");
        }
    }

    public static getInstance(): PingService {
        if (!PingService.instance) {
            PingService.instance = new PingService();
        }
        return PingService.instance;
    }

    public async setupRepeatingPing() {
        // clear timeout
        if (this.timeout != undefined) clearTimeout(this.timeout);
        // schedule next ping
        this.timeout = setTimeout(async () => {
            await this.repeatingPing();
        }, PingService.intervalMilliseconds);
    }

    public async repeatingPing() {
        // configure the next ping
        await this.setupRepeatingPing();
        // perform ping
        await this.ping();
    }

    public async ping() {
        // get current device config. There is nothing to do if the device has not been configured.
        const deviceStore = useDeviceStore();
        const deviceConfig = deviceStore.deviceConfig;
        if (!deviceConfig) return;
        const deviceToken = deviceConfig.deviceId;
        const lastUpdatedDate = deviceConfig.updatedAt.toDate();
        const runningVersion = import.meta.env.PACKAGE_VERSION;

        try {
            const response = await api.timeRecorderService.timeRecorderControllerPing({
                body: {
                    deviceToken,
                    lastUpdatedDate,
                    runningVersion,
                },
            });
            await deviceStore.storeDeviceConfigFromPing(response);
        } catch (error) {
            console.log(error);
            const axiosError = error as AxiosError;
            if (axiosError?.response?.status === 410) {
                // the device was deleted in the backend
                await deviceStore.reset();
                location.reload();
            } else {
                Sentry.captureException(error);
            }
        }
    }
}
