import { createApp } from "vue";
import router from "@/router";
import AppMain from "@/App.vue";

import * as Sentry from "@sentry/vue";

import { IonicVue } from "@ionic/vue";

import Vue3Toastify from "vue3-toastify";

import "./main.css";
import "vue3-toastify/dist/index.css";

/* LOCALIZATION */
import { i18n } from "@/locales/i18n";

/* PINIA STORE */
import { createPinia } from "pinia";
import { registerSW } from "virtual:pwa-register";
import { App, AppState } from "@capacitor/app";
import { PingService } from "@/services/ping.service";

// auto update the pwa as soon as changes in the precache manifest are detected
const intervalMS = 60 * 1000;

registerSW({
    immediate: true,
    onRegistered(r) {
        r &&
            setInterval(() => {
                r.update();
            }, intervalMS);
    },
});

const pinia = createPinia();

const app = createApp(AppMain).use(IonicVue).use(router).use(pinia).use(i18n);

app.use(Vue3Toastify, {
    autoClose: 3000,
    position: "top-center",
    transition: "slide",
    hideProgressBar: true,
});

if (import.meta.env.PROD) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        ignoreErrors: [
            "Network Error", // Ignore all network errors
            /timeout of \d+ms exceeded/, // Ignore axios timeout errors
            "Request canceled", // Ignore request cancellation errors
            // You can add more error messages or regex patterns here
        ],
        tracesSampleRate: 1.0,
        environment: import.meta.env.MODE,
    });
}

router
    .isReady()
    .then(() => {
        app.mount("#app");
    })
    .then(() => {
        return PingService.getInstance().repeatingPing();
    });

App.addListener("appStateChange", async (state: AppState) => {
    if (state.isActive) {
        await PingService.getInstance().repeatingPing();
    }
});
