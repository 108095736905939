import * as Sentry from "@sentry/vue";
import { ref } from "vue";
import { useDeviceStore } from "@/stores/device.store";
import { RecordTimesMode } from "@/interfaces/record-times-mode.enum";
import { api } from "@/services/api";
import { RecordedAssignmentWithEmployeeNameDto, RecordEndTimeDto, RecordStartTimeDto } from "@/services/api/services";
import { AxiosError } from "axios";
import { RecordTimeErrorCode } from "@/interfaces/record-time-error-codes.enum";

export const useRecordTime = () => {
    const isLoading = ref(false);
    const deviceStore = useDeviceStore();

    const recordTime = async (mode: RecordTimesMode, pin: string, photo?: string) => {
        isLoading.value = true;
        let recordedAssignments: RecordedAssignmentWithEmployeeNameDto[] | undefined;
        try {
            switch (mode) {
                case RecordTimesMode.RECORD_START_TIME:
                    recordedAssignments = await recordStartTime(pin, photo);
                    break;
                case RecordTimesMode.RECORD_END_TIME:
                    recordedAssignments = await recordEndTime(pin, photo);
                    break;
                case RecordTimesMode.RECORD_BREAK_END_TIME:
                    recordedAssignments = await recordBreakEndTime(pin);
                    break;
                case RecordTimesMode.RECORD_BREAK_START_TIME:
                    recordedAssignments = await recordBreakStartTime(pin);
                    break;
            }
            return recordedAssignments;
        } catch (e) {
            const axiosError = e as AxiosError;
            const errorCode = (axiosError?.response?.data as any)?.code;
            if (errorCode !== RecordTimeErrorCode.UNKNOWN_PIN) {
                Sentry.captureException(e);
            }
            throw e;
        } finally {
            isLoading.value = false;
        }
    };

    const recordStartTime = async (pin: string, photo?: string) => {
        if (!deviceStore.deviceConfig) throw new Error("Can not record times without a device config.");
        const payload: RecordStartTimeDto = {
            authToken: deviceStore.deviceConfig.authToken,
            deviceToken: deviceStore.deviceConfig.deviceId,
            startTime: new Date().getTime(),
            photo,
        };
        return await api.timeRecorderService.postRecordStartTime({ code: pin, structureNodeId: deviceStore.deviceConfig.structureNodeId, body: payload });
    };

    const recordEndTime = async (pin: string, photo?: string) => {
        if (!deviceStore.deviceConfig) throw new Error("Can not record times without a device config.");
        const payload: RecordEndTimeDto = {
            authToken: deviceStore.deviceConfig.authToken,
            deviceToken: deviceStore.deviceConfig.deviceId,
            endTime: new Date().getTime(),
            photo,
        };
        return await api.timeRecorderService.postRecordEndTime({ code: pin, structureNodeId: deviceStore.deviceConfig.structureNodeId, body: payload });
    };

    const recordBreakStartTime = async (pin: string) => {
        if (!deviceStore.deviceConfig) throw new Error("Can not record times without a device config.");
        const payload: RecordStartTimeDto = {
            authToken: deviceStore.deviceConfig.authToken,
            deviceToken: deviceStore.deviceConfig.deviceId,
            startTime: new Date().getTime(),
        };
        return await api.timeRecorderService.postRecordBreakStartTime({ code: pin, structureNodeId: deviceStore.deviceConfig.structureNodeId, body: payload });
    };

    const recordBreakEndTime = async (pin: string) => {
        if (!deviceStore.deviceConfig) throw new Error("Can not record times without a device config.");
        const payload: RecordEndTimeDto = {
            authToken: deviceStore.deviceConfig.authToken,
            deviceToken: deviceStore.deviceConfig.deviceId,
            endTime: new Date().getTime(),
        };
        return await api.timeRecorderService.postRecordBreakEndTime({ code: pin, structureNodeId: deviceStore.deviceConfig.structureNodeId, body: payload });
    };

    return {
        recordTime,
        isLoading,
    };
};
