<template>
    <div>
        {{ timeAsString }}
    </div>
</template>

<script setup lang="ts">
    import dayjs from "dayjs";
    import localizedFormat from "dayjs/plugin/localizedFormat";
    import { useNow } from "@vueuse/core";
    import { useI18n } from "vue-i18n";
    import { computed } from "vue";

    const { d } = useI18n({ useScope: "global" });
    const now = useNow({ interval: 1000 });

    const timeAsString = computed(() => d(now.value, "clock"));

    // Extend dayjs with localization
    dayjs.extend(localizedFormat);
</script>

<style scoped></style>
