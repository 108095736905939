<template>
    <info-modal v-model:is-open="isOpen" :title-icon="sad" type="error" :title="t('timeRecordingError.title')">
        <template #body>
            <h2>{{ errorMessage }}</h2>
        </template>
        <template #footer>
            <button class="rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-neutral-200" @click="isOpen = false">
                {{ t("close") }}
            </button>
        </template>
    </info-modal>
</template>

<script setup lang="ts">
    import InfoModal from "@/components/modals/InfoModal.vue";
    import { sad } from "ionicons/icons";
    import { AxiosError } from "axios";
    import { computed } from "vue";
    import { RecordTimesMode } from "@/interfaces/record-times-mode.enum";
    import { RecordTimeErrorCode } from "@/interfaces/record-time-error-codes.enum";
    import { useI18n } from "vue-i18n";

    const { t } = useI18n({ useScope: "global" });

    const isOpen = defineModel("isOpen", { type: Boolean, required: true });

    const props = defineProps({
        axiosError: {
            type: Object as () => AxiosError,
            required: true,
        },
        timeRecordingMode: {
            type: String as () => RecordTimesMode,
            required: true,
        },
    });

    const errorCode = computed((): number => {
        return (props.axiosError?.response?.data as any)?.code ?? -1;
    });

    const axiosErrorMessage = computed((): string => {
        return (props.axiosError?.response?.data as any)?.message ?? props.axiosError?.message ?? JSON.stringify(props.axiosError);
    });

    const errorMessage = computed(() => {
        switch (errorCode.value) {
            case RecordTimeErrorCode.UNKNOWN_PIN:
                return t("timeRecordingError.invalidPin");
            case RecordTimeErrorCode.NO_OPEN_RECORDED_ASSIGNMENT:
                switch (props.timeRecordingMode) {
                    case RecordTimesMode.RECORD_END_TIME:
                        return t("timeRecordingError.canNotEndWithoutStart");
                    case RecordTimesMode.RECORD_BREAK_START_TIME:
                        return t("timeRecordingError.canNotStartBreakWithoutStart");
                    case RecordTimesMode.RECORD_START_TIME:
                    case RecordTimesMode.RECORD_BREAK_END_TIME:
                        // this should never happen
                        return t("timeRecordingError.noStart");
                    default:
                        return "Unknown RecordTimesMode";
                }
            case RecordTimeErrorCode.EXISTING_OPEN_RECORDED_ASSIGNMENT:
                switch (props.timeRecordingMode) {
                    case RecordTimesMode.RECORD_START_TIME:
                        return t("timeRecordingError.canNotStartBecauseAlreadyStarted");
                    case RecordTimesMode.RECORD_BREAK_END_TIME:
                        return t("timeRecordingError.canNotEndBreakBecauseNoBreakStarted");
                    case RecordTimesMode.RECORD_END_TIME:
                    case RecordTimesMode.RECORD_BREAK_START_TIME:
                        // this should never happen
                        return t("timeRecordingError.existingOpenRecordedAssignment");
                    default:
                        return "Unknown RecordTimesMode";
                }
            case RecordTimeErrorCode.RECORD_END_TIME_BEFORE_START_TIME:
                return t("timeRecordingError.endTimeMustBeBeforeStartTime");
            case RecordTimeErrorCode.EXPIRED_WORK_PERMIT:
                return t("timeRecordingError.expiredWorkPermit");
            case RecordTimeErrorCode.EXPIRED_RESIDENCE_PERMIT:
                return t("timeRecordingError.expiredResidencePermit");
            case RecordTimeErrorCode.NO_VALID_WORKING_CONTRACT:
                return t("timeRecordingError.noValidWorkingContract");
            case RecordTimeErrorCode.CLOCKING_INTO_OTHER_STRUCTURENODE_NOT_ALLOWED:
                return t("timeRecordingError.allowClockingIntoAnotherStructureNodeWithoutReleasedShift");
            case RecordTimeErrorCode.CLOCKING_IN_DURING_REST_PERIOD_NOT_ALLOWED:
                return t("timeRecordingError.clockingInDuringRestPeriodNotAllowed");
            default:
                return axiosErrorMessage.value;
        }
    });
</script>
