import axios, { AxiosDefaults, AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from "axios";
import { DeviceService, TimeRecorderService, serviceOptions } from "./services";
import { useDeviceStore } from "@/stores/device.store";

export class APIClient {
    public deviceService: DeviceService;
    public timeRecorderService: TimeRecorderService;
    private httpClient: AxiosInstance;

    public constructor(config: AxiosRequestConfig) {
        // create axios client based on the given configuration and set it as default for the generated services
        serviceOptions.axios = axios.create(config);
        this.httpClient = serviceOptions.axios;

        // initialize all services so we only have one instance per service which is using the default configuration
        this.deviceService = new DeviceService();
        this.timeRecorderService = new TimeRecorderService();

        this.httpClient.interceptors.request.use(
            function(config) {
                const deviceStore = useDeviceStore();
                const authToken = deviceStore.deviceConfig?.authToken;
                const deviceToken = deviceStore.deviceConfig?.deviceId;
                return {
                    ...config,
                    data: {
                        ...config.data,
                        authToken,
                        deviceToken,
                    },
                };
            },
            function(error) {
                return Promise.reject(error);
            },
        );
    }

    public get defaults(): AxiosDefaults {
        return this.httpClient.defaults;
    }

    public get interceptors(): AxiosInterceptors {
        return this.httpClient.interceptors;
    }

    public use(func: MiddlewareFunc): APIClient {
        func(this);
        return this;
    }
}

export interface AxiosInterceptors {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
}

export type MiddlewareFunc = (axios: APIClient) => void;
