import { defineStore } from "pinia";
import { DevicePingDto, LoadDeviceConfigDto } from "@/services/api/services";
import dayjs from "dayjs";
import { api } from "@/services/api";
import { StorageService } from "@/services/storage.service";
import { v4 as uuidv4 } from "uuid";
import { getPlatforms } from "@ionic/vue";
import { computed, ref } from "vue";
import * as Sentry from "@sentry/vue";
import { DeviceConfig } from "@/interfaces/device-config.interface";

export const useDeviceStore = defineStore("device", () => {
    const deviceConfig = ref<DeviceConfig | undefined>(undefined);

    const getStoredConfig = async (): Promise<DeviceConfig | undefined> => {
        const storageService = await StorageService.getInstance();

        const authToken = await storageService.get("authToken");
        if (!authToken) return undefined;

        const deviceId = await storageService.get("deviceId");
        const startCode = await storageService.get("startCode");
        const structureNodeId = await storageService.get("structureNodeId");
        const pinLength = await storageService.get("pinLength");
        const showBreakButtons = await storageService.get("showBreakButtons");
        const takePictureWhenClockingInOrOut = await storageService.get("takePictureWhenClockingInOrOut");
        const updatedAt = dayjs(await storageService.get("updatedAt"));
        const location = await storageService.get("location");

        return {
            deviceId,
            startCode,
            authToken,
            structureNodeId,
            pinLength,
            showBreakButtons,
            takePictureWhenClockingInOrOut,
            updatedAt,
            location,
        };
    };

    const init = async () => {
        if (!deviceConfig.value) deviceConfig.value = await getStoredConfig();
    };

    const fetchDeviceConfigFromBackend = async (loadDeviceConfig: LoadDeviceConfigDto) => {
        const deviceConfigResponse = await api.deviceService.getConfiguration({ body: loadDeviceConfig });
        const storageService = await StorageService.getInstance();

        await storageService.set("deviceId", loadDeviceConfig.deviceId);
        await storageService.set("startCode", loadDeviceConfig.startCode);
        await storageService.set("authToken", deviceConfigResponse.authToken);
        await storageService.set("structureNodeId", deviceConfigResponse.structureNodeId);
        await storageService.set("pinLength", deviceConfigResponse.pinLength);
        await storageService.set("showBreakButtons", deviceConfigResponse.showBreakButtons);
        await storageService.set("takePictureWhenClockingInOrOut", deviceConfigResponse.takePictureWhenClockingInOrOut);
        await storageService.set("location", deviceConfigResponse.location);
        await storageService.set("updatedAt", dayjs().toISOString());

        deviceConfig.value = await getStoredConfig();

        Sentry.setContext("device", {
            deviceId: deviceConfig.value?.deviceId,
            structureNodeId: deviceConfig.value?.structureNodeId,
        });
    };

    const storeDeviceConfigFromPing = async (devicePingResponse: DevicePingDto) => {
        if (!deviceConfig.value) return;
        const storageService = await StorageService.getInstance();

        if (devicePingResponse.configuration?.pinLength != undefined) {
            await storageService.set("pinLength", devicePingResponse.configuration?.pinLength);
            deviceConfig.value.pinLength = devicePingResponse.configuration?.pinLength;
        }
        if (devicePingResponse.configuration?.showBreakButtons != undefined) {
            await storageService.set("showBreakButtons", devicePingResponse.configuration?.showBreakButtons);
            deviceConfig.value.showBreakButtons = devicePingResponse.configuration?.showBreakButtons;
        }
        if (devicePingResponse.configuration?.takePictureWhenClockingInOrOut != undefined) {
            await storageService.set("takePictureWhenClockingInOrOut", devicePingResponse.configuration?.takePictureWhenClockingInOrOut);
            deviceConfig.value.takePictureWhenClockingInOrOut = devicePingResponse.configuration?.takePictureWhenClockingInOrOut;
        }

        if (devicePingResponse.location) {
            await storageService.set("location", devicePingResponse.location);
            deviceConfig.value.location = devicePingResponse?.location;
        }

        const updatedAt = dayjs();
        await storageService.set("updatedAt", updatedAt.toISOString());
        deviceConfig.value.updatedAt = updatedAt;

        Sentry.setContext("device", {
            deviceId: deviceConfig.value?.deviceId,
            structureNodeId: deviceConfig.value?.structureNodeId,
        });
    };

    const isAuthenticated = computed(() => {
        return !!deviceConfig.value?.authToken;
    });

    const register = async (startCode: string) => {
        const deviceId = uuidv4();
        const params: LoadDeviceConfigDto = {
            os: getPlatforms().join(","),
            startCode,
            deviceId,
        };
        await fetchDeviceConfigFromBackend(params);
    };

    const reset = async () => {
        deviceConfig.value = undefined;
        const storageService = await StorageService.getInstance();
        Sentry.setContext("device", {});
        return storageService.clear();
    };

    return { register, init, isAuthenticated, storeDeviceConfigFromPing, deviceConfig, reset };
});
