<template>
    <div class="flex size-full flex-row p-4 xl:p-10">
        <div class="mr-8 w-1/2 xl:mr-12">
            <h1 class="mb-2 text-2xl xl:mb-4">{{ t("registerDevice.title") }}</h1>
            <p>{{ t("registerDevice.description") }}</p>

            <div class="flex flex-col items-center justify-center">
                <div class="mx-auto my-2 flex">
                    <input
                        v-for="i in startCodeLength"
                        :key="i - 1"
                        v-model="startCode[i - 1]"
                        class="mx-1 block h-16 w-12 rounded-md border-2 border-neutral-600 bg-neutral-100 text-center text-5xl dark:bg-neutral-800"
                        inputmode="none"
                        type="text"
                    />
                </div>
                <pin-entry-keyboard :show-abort-button="false" class="mt-2 w-96 max-w-screen-md" @cancel="cancel" @add-digit="addDigit" @remove-last-digit="removeLastDigit" />

                <button class="mt-4 rounded-sm bg-primary px-4 py-2 text-2xl text-neutral-100 xl:mt-6 xl:px-6 xl:py-4" @click="registerDevice">
                    {{ t("registerDevice.registerDeviceButton") }}
                </button>
            </div>
        </div>
        <div class="w-1/2">
            <h2 class="mb-3 text-xl">{{ t("registerDevice.generateStartCode.header") }}</h2>

            <ol class="list-disc pl-6">
                <li>{{ t("registerDevice.generateStartCode.login") }}</li>
                <li>{{ t("registerDevice.generateStartCode.navigateSettings") }}</li>
                <li>{{ t("registerDevice.generateStartCode.openDevices") }}</li>
                <li>{{ t("registerDevice.generateStartCode.createNewDevice", { length: startCodeLength }) }}</li>
            </ol>

            <p class="mt-4">{{ t("registerDevice.generateStartCode.rememberStartCode") }}</p>
            <p class="mt-4">{{ t("registerDevice.generateStartCode.contactSupport") }} <a href="mailto:support@nesto-software.de">support@nesto-software.de</a></p>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import PinEntryKeyboard from "@/components/PinEntryKeyboard.vue";
    import { useRoute } from "vue-router";
    import { useRegisterDevice } from "@/services/register-device.service";
    import { useI18n } from "vue-i18n";
    import { onMounted } from "vue";

    const { startCode, registerDevice, startCodeLength } = useRegisterDevice();
    const { t } = useI18n({ useScope: "global" });

    const route = useRoute();
    const autoFillStartCode = async () => {
        if (route.query.startCode && !Array.isArray(route.query.startCode)) {
            startCode.value = route.query.startCode;
            await registerDevice();
        }
    };

    onMounted(autoFillStartCode);

    const addDigit = (digit: string) => {
        if (startCode.value.length >= startCodeLength) {
            return;
        }
        startCode.value += digit;
    };

    const cancel = () => {
        startCode.value = "";
    };

    const removeLastDigit = () => {
        startCode.value = startCode.value.slice(0, -1);
    };
</script>

<style scoped></style>
