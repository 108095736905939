import { APIClient } from "./client";

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
const shortDateFormat = /^\d{4}-\d{2}-\d{2}$/;

/**
 * Check if string values are a date format and transform them to a date
 *
 * @param {string} key not used, but needed for the JSON parse reviver function
 * @param  value If string, it is tested if the value is a date and in case it is, it will be transformed
 * @returns value which may be transformed
 */
function reviver(key: string, value: unknown): unknown {
    if (typeof value === "string" && (dateFormat.test(value) || shortDateFormat.test(value))) {
        return new Date(value);
    }

    return value;
}

const api = new APIClient({
    baseURL: import.meta.env.VITE_APP_BASE_URL,
    timeout: 20 * 1000,
    transformResponse: [
        function(data) {
            if (typeof data === "string") {
                try {
                    data = JSON.parse(data, reviver);
                } catch (e) {
                    /* Ignore */
                }
            }
            return data;
        },
    ],
    headers: {
        clientVersion: "1.49",
    },
});

export { api };
