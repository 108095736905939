export enum RecordTimesMode {
    RECORD_START_TIME = "recordStartTime",
    RECORD_END_TIME = "recordEndTime",
    RECORD_BREAK_START_TIME = "recordBreakStartTime",
    RECORD_BREAK_END_TIME = "recordBreakEndTime",
}

export function isRecordTimesMode(s: string): boolean {
    return Object.values(RecordTimesMode).includes(s as any);
}
