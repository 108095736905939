import { IntlDateTimeFormats } from "vue-i18n";
const datetimeFormats: IntlDateTimeFormats = {
    de: {
        clock: {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        },
        time: {
            hour: "numeric",
            minute: "numeric",
        },
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        shortDateTime: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        },
        long: {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
    },
    en: {
        clock: {
            hour12: false,
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        },
        time: {
            hour: "numeric",
            minute: "numeric",
        },
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        shortDateTime: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        },
        long: {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
    },
    pl: {
        clock: {
            hour12: false,
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        },
        time: {
            hour: "numeric",
            minute: "numeric",
        },
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        shortDateTime: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        },
        long: {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
    },
};

export default datetimeFormats;
