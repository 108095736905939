<template>
    <footer-info-chip :class="onlineStateClass">{{ onlineStateLabel }}</footer-info-chip>
    <footer-info-chip v-if="lastConnectionDate" class="whitespace-nowrap" :class="connectionClass">
        {{ t("lastConnection") }}: {{ t("timeDiffMinutes", { n: minutesSinceLastConnection }) }}
    </footer-info-chip>
</template>

<script setup lang="ts">
    import { computed } from "vue";
    import { useI18n } from "vue-i18n";
    import { useConnectionState } from "@/services/connection-state.service";
    import FooterInfoChip from "@/components/footer/FooterInfoChip.vue";

    const { t } = useI18n({ useScope: "global" });

    const { isOnline, lastConnectionDate, hasRecentLastConnection, minutesSinceLastConnection } = useConnectionState();

    const connectionClass = computed(() => (hasRecentLastConnection.value ? "bg-success" : "bg-danger"));
    const onlineStateClass = computed(() => (isOnline.value ? "bg-success" : "bg-danger"));

    const onlineStateLabel = computed(() => (isOnline.value ? t("online") : t("offline")));
</script>

<style scoped></style>
