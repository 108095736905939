import { IntlNumberFormats } from "vue-i18n";
const numberFormats: IntlNumberFormats = {
    de: {
        currency: {
            style: "currency",
            currency: "EUR",
            notation: "standard",
        },
        decimal: {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
    en: {
        currency: {
            style: "currency",
            currency: "EUR",
            notation: "standard",
        },
        decimal: {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
    pl: {
        currency: {
            style: "currency",
            currency: "EUR",
            notation: "standard",
        },
        decimal: {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
};

export default numberFormats;
