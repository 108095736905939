import { useDeviceStore } from "@/stores/device.store";
import { useNow, useOnline } from "@vueuse/core";
import dayjs from "dayjs";
import { computed } from "vue";

export const useConnectionState = () => {
    const isOnline = useOnline();
    const deviceStore = useDeviceStore();
    const now = useNow({ interval: 60 * 1000 }); // update once per minute

    const lastConnectionDate = computed(() => {
        return deviceStore.deviceConfig?.updatedAt;
    });

    const hasRecentLastConnection = computed(() => {
        if (!lastConnectionDate.value) return false;
        // expect the last successful connection to be less than 2 minutes in the past
        return minutesSinceLastConnection.value < 2;
    });

    const minutesSinceLastConnection = computed(() => {
        return dayjs(now.value).diff(lastConnectionDate.value, "minutes");
    });

    const isConnected = computed(() => {
        return isOnline.value && hasRecentLastConnection.value;
    });

    return {
        isOnline,
        isConnected,
        lastConnectionDate,
        hasRecentLastConnection,
        minutesSinceLastConnection,
    };
};
