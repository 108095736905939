import router from "@/router";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/vue";
import { ref } from "vue";
import { useDeviceStore } from "@/stores/device.store";
import { useI18n } from "vue-i18n";
import { toast } from "vue3-toastify";

export const useRegisterDevice = () => {
    const startCode = ref("");
    const isLoading = ref(false);
    const deviceStore = useDeviceStore();
    const { t } = useI18n({ useScope: "global" });
    const startCodeLength = 8;

    const registerDevice = async () => {
        if (startCode.value.length !== startCodeLength || isLoading.value) {
            toast(t("timeRecordingError.enterAStartCode", { length: startCodeLength }), {
                type: "error",
            });
            return;
        }
        isLoading.value = true;

        try {
            await deviceStore.register(startCode.value);
            await router.replace({ name: "Root" });
            return true;
        } catch (error) {
            const axiosError = error as AxiosError;
            let message = "";
            const detailedErrors = (axiosError?.response?.data as any)?.errors;
            if (detailedErrors && detailedErrors.length > 0) {
                message = detailedErrors[0].message;
            } else {
                message = (axiosError?.response as any)?.message ?? axiosError?.message ?? error;
            }
            toast(t("somethingWentWrong") + "\n\n" + t("registerDevice.registerDeviceFailed") + "\n\n" + message, {
                type: "error",
            });
            startCode.value = "";

            Sentry.captureException(error);
            console.log(error);

            return false;
        } finally {
            isLoading.value = false;
        }
    };

    return {
        startCode,
        isLoading,
        registerDevice,
        startCodeLength,
    };
};
