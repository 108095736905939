<template>
    <div class="size-full">
        <current-time-clock class="mb-8 mt-4 text-center text-6xl font-bold 2xl:m-12 2xl:text-9xl"></current-time-clock>

        <div class="mx-8 grid h-3/4 grid-cols-2 gap-x-4 gap-y-8 xl:mx-24 xl:gap-x-12 xl:gap-y-16">
            <main-menu-button :class="buttonDisabledClass" :icon="logIn" class="bg-primary" @click="checkOnlineStateAndContinue(RecordTimesMode.RECORD_START_TIME)">
                {{ t(`timeRecordingMode.${RecordTimesMode.RECORD_START_TIME}`) }}
            </main-menu-button>
            <main-menu-button :class="buttonDisabledClass" :icon="home" class="bg-warning" @click="checkOnlineStateAndContinue(RecordTimesMode.RECORD_END_TIME)">
                {{ t(`timeRecordingMode.${RecordTimesMode.RECORD_END_TIME}`) }}
            </main-menu-button>
            <main-menu-button
                v-if="deviceStore.deviceConfig?.showBreakButtons"
                :class="buttonDisabledClass"
                :icon="cafe"
                class="bg-secondary"
                @click="checkOnlineStateAndContinue(RecordTimesMode.RECORD_BREAK_START_TIME)"
            >
                {{ t(`timeRecordingMode.${RecordTimesMode.RECORD_BREAK_START_TIME}`) }}
            </main-menu-button>
            <main-menu-button
                v-if="deviceStore.deviceConfig?.showBreakButtons"
                :class="buttonDisabledClass"
                :icon="logIn"
                class="bg-secondary"
                @click="checkOnlineStateAndContinue(RecordTimesMode.RECORD_BREAK_END_TIME)"
            >
                {{ t(`timeRecordingMode.${RecordTimesMode.RECORD_BREAK_END_TIME}`) }}
            </main-menu-button>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import MainMenuButton from "@/components/MainMenuButton.vue";
    import { useDeviceStore } from "@/stores/device.store";
    import { useRouter } from "vue-router";
    import { RecordTimesMode } from "@/interfaces/record-times-mode.enum";
    import CurrentTimeClock from "@/components/CurrentTimeClock.vue";
    import { cafe, home, logIn } from "ionicons/icons";
    import { useConnectionState } from "@/services/connection-state.service";
    import { PingService } from "@/services/ping.service";
    import { useI18n } from "vue-i18n";
    import { computed, onMounted } from "vue";
    import { toast } from "vue3-toastify";

    const router = useRouter();
    const { t } = useI18n({ useScope: "global" });

    const deviceStore = useDeviceStore();

    const recordTime = async (mode: RecordTimesMode) => {
        await router.replace({ name: "PinEntry", query: { mode } });
    };

    const { isConnected } = useConnectionState();

    onMounted(() => {
        PingService.getInstance().ping();
    });

    function checkOnlineStateAndContinue(mode: RecordTimesMode) {
        if (isConnected.value) {
            recordTime(mode);
        } else {
            toast.error(t("deviceIsOffline"));
        }
    }

    const buttonDisabledClass = computed(() => (isConnected.value ? "" : "!bg-neutral-300 brightness-90"));
</script>
