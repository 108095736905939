import { createRouter, createWebHistory } from "vue-router";
import { RouteRecordRaw } from "vue-router";
import { useDeviceStore } from "@/stores/device.store";
import RecordTimesPage from "@/views/RecordTimesPage.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import PinEntryPage from "@/views/PinEntryPage.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Root",
        redirect: "/recordTimes",
    },
    {
        path: "/register",
        name: "Register",
        component: RegisterPage,
    },
    {
        path: "/recordTimes",
        name: "RecordTimes",
        component: RecordTimesPage,
    },
    {
        path: "/pinEntry",
        name: "PinEntry",
        component: PinEntryPage,
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const deviceStore = useDeviceStore();
    await deviceStore.init(); // ensure the deviceStore is initialized
    const isAuthenticated = deviceStore.isAuthenticated;

    if (!isAuthenticated) {
        if (to.path !== "/register") next({ path: "/register" });
        else next();
    } else {
        if (to.path === "/register") next({ path: "/" });
        else next();
    }
});

export default router;
