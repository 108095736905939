<template>
    <div class="grid auto-rows-max grid-cols-3 grid-rows-4 gap-2">
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('1')">1</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('2')">2</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('3')">3</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('4')">4</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('5')">5</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('6')">6</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('7')">7</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('8')">8</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('9')">9</PinEntryButton>
        <PinEntryButton v-if="showAbortButton" class="bg-red-500" @click="cancel">
            <ion-icon :icon="arrowBack" size="large" />
        </PinEntryButton>
        <div v-else></div>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" @click="addDigit('0')">0</PinEntryButton>
        <PinEntryButton :class="buttonDisabledClass" :disabled="disabled" class="bg-neutral-600" @click="removeLastDigit">
            <ion-icon :icon="backspace" size="large" />
        </PinEntryButton>
    </div>
</template>

<script setup lang="ts">
    import { backspace, arrowBack } from "ionicons/icons";
    import { IonIcon } from "@ionic/vue";

    import { computed, defineEmits } from "vue";
    import PinEntryButton from "./PinEntryButton.vue";

    const props = withDefaults(
        defineProps<{
            disabled?: boolean;
            showAbortButton?: boolean;
        }>(),
        {
            disabled: false,
            showAbortButton: true,
        },
    );

    const emit = defineEmits<{
        addDigit: [digit: string];
        cancel: [];
        removeLastDigit: [];
    }>();

    const addDigit = (digit: string) => {
        emit("addDigit", digit);
    };

    const cancel = () => {
        emit("cancel");
    };

    const removeLastDigit = () => {
        emit("removeLastDigit");
    };

    const buttonDisabledClass = computed(() => (props.disabled ? "!bg-neutral-300 brightness-90" : ""));
</script>
