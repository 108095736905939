import { createI18n } from "vue-i18n";
import en from "./languages/en.json";
import de from "./languages/de.json";
import pl from "./languages/pl.json";
import numberFormats from "@/locales/numberFormats";
import datetimeFormats from "@/locales/dateFormats";

import { Device } from "@capacitor/device";
const i18n = createI18n({
    locale: "de",
    fallbackLocale: "en",
    preserveDirectiveContent: true,
    globalInjection: true,
    messages: { en, de, pl },
    legacy: false,
    numberFormats,
    datetimeFormats,
});

const getUserLocale = async (): Promise<string> => {
    const deviceLocale = await Device.getLanguageTag();
    const locale = deviceLocale.value.split("-")[0];
    return locale;
};

const loadCurrentLocale = async () => {
    const locale = await getUserLocale();
    i18n.global.locale.value = locale;
};

export { i18n, loadCurrentLocale };
