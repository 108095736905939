<template>
    <button class="mx-1 whitespace-nowrap rounded-sm px-2 py-1 text-neutral-800 dark:text-neutral-100" @click="showModal">{{ t("deviceInfo.title") }}</button>

    <info-modal v-model:is-open="isModalOpen" :title="t('deviceInfo.title')" class="text-base">
        <template #body>
            <div class="grid grid-cols-1 gap-4 text-neutral-600 xl:grid-cols-2 dark:text-neutral-200">
                <div class="flex items-center">
                    <div class="text-gray-500 dark:text-gray-400">{{ t("deviceInfo.appVersion") }}</div>
                    <div class="ml-auto">{{ appVersion }}</div>
                </div>
                <div class="flex items-center">
                    <div class="text-gray-500 dark:text-gray-400">{{ t("deviceInfo.deviceName") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.location }}</div>
                </div>
                <div class="flex items-center">
                    <div class="text-gray-500 dark:text-gray-400">{{ t("deviceInfo.deviceId") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.deviceId }}</div>
                </div>
                <div class="flex items-center">
                    <div class="text-gray-500 dark:text-gray-400">{{ t("deviceInfo.structureNodeId") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.structureNodeId }}</div>
                </div>
                <div class="flex items-center">
                    <div class="text-gray-500 dark:text-gray-400">{{ t("deviceInfo.pinLength") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.pinLength }}</div>
                </div>
                <div class="flex items-center">
                    <div class="text-gray-500 dark:text-gray-400">{{ t("deviceInfo.showBreakButtons") }}</div>
                    <div class="ml-auto">{{ deviceStore.deviceConfig?.showBreakButtons }}</div>
                </div>
                <!--                <div class="flex items-center">-->
                <!--                    <div class="text-gray-500 dark:text-gray-400">{{ t("deviceInfo.takePicture") }}</div>-->
                <!--                    <div class="ml-auto">{{ deviceStore.deviceConfig?.takePictureWhenClockingInOrOut }}</div>-->
                <!--                </div>-->
                <div v-if="lastUpdatedDate" class="flex items-center">
                    <div class="text-gray-500 dark:text-gray-400">{{ t("deviceInfo.lastUpdate") }}</div>
                    <div class="ml-auto">{{ $d(lastUpdatedDate, "long") }}</div>
                </div>
            </div>
        </template>
        <template #footer>
            <reset-device-button v-model:is-open="isResetModalOpen"></reset-device-button>
        </template>
    </info-modal>
</template>

<script lang="ts" setup>
    import { computed, onBeforeUnmount, ref } from "vue";
    import { useDeviceStore } from "@/stores/device.store";
    import ResetDeviceButton from "@/components/ResetDeviceButton.vue";
    import { useAutoDismiss } from "@/services/auto-dismiss.service";
    import InfoModal from "@/components/modals/InfoModal.vue";
    import { useI18n } from "vue-i18n";

    const { t } = useI18n({ useScope: "global" });
    const isModalOpen = ref(false);
    const isResetModalOpen = ref(false);
    const deviceStore = useDeviceStore();

    const appVersion = import.meta.env.PACKAGE_VERSION;

    const closeModal = async () => {
        isResetModalOpen.value = false;
        isModalOpen.value = false;
        cancelTimeout();
    };

    const { resetTimeout, cancelTimeout } = useAutoDismiss(closeModal, 30);

    const showModal = () => {
        isModalOpen.value = true;
        resetTimeout();
    };

    const lastUpdatedDate = computed(() => {
        if (!deviceStore.deviceConfig) return undefined;
        return deviceStore.deviceConfig?.updatedAt.toDate();
    });

    onBeforeUnmount(() => {
        cancelTimeout();
    });
</script>

<style scoped></style>
