import { Storage } from "@ionic/storage";

export class StorageService {
    private static instance: StorageService;
    private storage: Storage;

    private constructor() {
        if (StorageService.instance) {
            throw new Error("Use PreferencesService.getInstance() instead of new");
        }
        this.storage = new Storage();
    }

    private async createStorage() {
        return this.storage.create();
    }

    public static async getInstance(): Promise<StorageService> {
        if (!StorageService.instance) {
            StorageService.instance = new StorageService();
            await StorageService.instance.createStorage();
        }
        return StorageService.instance;
    }

    public get(key: string) {
        return this.storage.get(key);
    }

    public set(key: string, value: any) {
        return this.storage.set(key, value);
    }

    public remove(key: string) {
        return this.storage.remove(key);
    }

    public clear() {
        return this.storage.clear();
    }
}
