export const useAutoDismiss = (callback: () => Promise<void>, closeAutomaticallyAfterSeconds = 30) => {
    let timeout: ReturnType<typeof setTimeout> | undefined = undefined;

    const resetTimeout = () => {
        cancelTimeout();
        timeout = setTimeout(() => {
            callback();
        }, closeAutomaticallyAfterSeconds * 1000);
    };

    const cancelTimeout = () => {
        if (timeout != undefined) clearTimeout(timeout);
    };

    return {
        resetTimeout,
        cancelTimeout,
    };
};
