<template>
    <div v-if="isModalOpen" class="fixed left-0 top-0 z-50 flex size-full items-center justify-center overflow-y-auto overflow-x-hidden bg-neutral-700/50 dark:bg-neutral-700/80">
        <div class="relative max-h-full w-full max-w-4xl p-4 text-neutral-700 dark:text-neutral-100">
            <!-- Modal content -->
            <div class="relative rounded-lg bg-neutral-100 shadow dark:bg-neutral-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between rounded-t border-b border-neutral-300 p-4 md:p-5 dark:border-neutral-600">
                    <ion-icon v-if="titleIcon" :class="headerTextColorClass" :icon="titleIcon" class="mr-2" size="large"></ion-icon>
                    <span :class="headerTextColorClass" class="text-2xl font-semibold">{{ title }}</span>

                    <button class="ms-auto inline-flex size-8 items-center justify-center rounded-lg bg-transparent text-sm text-neutral-800 dark:text-neutral-400" @click="isModalOpen = false">
                        <ion-icon :icon="closeOutline" size="large" />
                    </button>
                </div>
                <!-- Modal body -->
                <div class="space-y-4 p-4 text-xl md:p-5">
                    <slot name="body">Content</slot>
                </div>

                <!-- Modal footer -->
                <div v-if="$slots.footer" class="flex items-center rounded-b border-t border-neutral-300 p-4 md:p-5 dark:border-neutral-600">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { closeOutline } from "ionicons/icons";
    import { IonIcon } from "@ionic/vue";
    import { computed } from "vue";

    const isModalOpen = defineModel("isOpen", { type: Boolean, required: true });

    const props = withDefaults(defineProps<{ title: string; titleIcon?: string; type?: "default" | "success" | "error" }>(), {
        titleIcon: undefined,
        type: "default",
    });

    const headerTextColorClass = computed(() => {
        switch (props.type) {
            case "success":
                return "text-success";
            case "error":
                return "text-danger";
            default:
                return "text-neutral-800 dark:text-neutral-300";
        }
    });
</script>
