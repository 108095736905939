<template>
    <button class="rounded-lg bg-danger px-5 py-2.5 text-center text-sm font-medium text-neutral-100" @click="isResetModalOpen = true">
        {{ t("resetDevice.buttonTitle") }}
    </button>

    <info-modal v-model:is-open="isResetModalOpen" :title="t('resetDevice.modalTitle')">
        <template #body>
            <p>{{ t("resetDevice.description") }}</p>

            <input
                v-model="startCode"
                :maxlength="startCodeLength"
                class="p-4 text-xl dark:bg-gray-800"
                :label="t('startCode')"
                min="1"
                type="text"
                :placeholder="t('startCode')"
                @keydown.enter="registerAsNewDevice"
            />
        </template>
        <template #footer>
            <button class="rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-neutral-100" @click="registerAsNewDevice">
                {{ t("resetDevice.registerAsNewDeviceButton") }}
            </button>
        </template>
    </info-modal>
</template>

<script setup lang="ts">
    import InfoModal from "@/components/modals/InfoModal.vue";
    import { useRegisterDevice } from "@/services/register-device.service";
    import { useI18n } from "vue-i18n";

    const { t } = useI18n({ useScope: "global" });

    const { startCode, registerDevice, startCodeLength } = useRegisterDevice();

    const isResetModalOpen = defineModel("isOpen", { type: Boolean, required: true });

    const registerAsNewDevice = async () => {
        const didRegisterAsNewDevice = await registerDevice();
        if (didRegisterAsNewDevice) {
            isResetModalOpen.value = false;
        }
    };
</script>

<style scoped></style>
