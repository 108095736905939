/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { type AxiosInstance, type AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /**
   * show loading status
   */
  loading?: boolean;
  /**
   * display error message
   */
  showError?: boolean;
  /**
   * data security, extended fields are encrypted using the specified algorithm
   */
  security?: Record<string, 'md5' | 'sha1' | 'aes' | 'des'>;
  /**
   * indicates whether Authorization credentials are required for the request
   * @default true
   */
  withAuthorization?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class TimeRecorderService {
  /**
   * Post Record Start Time
   */
  postRecordStartTime(
    params: {
      /**  */
      code: string;
      /**  */
      structureNodeId: string;
      /** requestBody */
      body?: RecordStartTimeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecordedAssignmentWithEmployeeNameDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/employeeApi/timeRecorderConnector/{structureNodeId}/employeeCodes/{code}/recordStartTime';
      url = url.replace('{code}', params['code'] + '');
      url = url.replace('{structureNodeId}', params['structureNodeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Post Record Break End Time
   */
  postRecordBreakEndTime(
    params: {
      /**  */
      code: string;
      /**  */
      structureNodeId: string;
      /** requestBody */
      body?: RecordEndTimeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecordedAssignmentWithEmployeeNameDto[]> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/employeeApi/timeRecorderConnector/{structureNodeId}/employeeCodes/{code}/recordBreakEndTime';
      url = url.replace('{code}', params['code'] + '');
      url = url.replace('{structureNodeId}', params['structureNodeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Post Record End Time
   */
  postRecordEndTime(
    params: {
      /**  */
      code: string;
      /**  */
      structureNodeId: string;
      /** requestBody */
      body?: RecordEndTimeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecordedAssignmentWithEmployeeNameDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/employeeApi/timeRecorderConnector/{structureNodeId}/employeeCodes/{code}/recordEndTime';
      url = url.replace('{code}', params['code'] + '');
      url = url.replace('{structureNodeId}', params['structureNodeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   * Post Record Break Start Time
   */
  postRecordBreakStartTime(
    params: {
      /**  */
      code: string;
      /**  */
      structureNodeId: string;
      /** requestBody */
      body?: RecordStartTimeDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RecordedAssignmentWithEmployeeNameDto[]> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/employeeApi/timeRecorderConnector/{structureNodeId}/employeeCodes/{code}/recordBreakStartTime';
      url = url.replace('{code}', params['code'] + '');
      url = url.replace('{structureNodeId}', params['structureNodeId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  timeRecorderControllerPing(
    params: {
      /** requestBody */
      body?: IncomingDevicePingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DevicePingDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/employeeApi/timeRecorderConnector/ping';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DeviceService {
  /**
   * Updates the device with its deviceId and returns the authentication token. Then the start code is invalidated
   */
  getConfiguration(
    params: {
      /** requestBody */
      body?: LoadDeviceConfigDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetDeviceConfigDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/timerecorder/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface RecordStartTimeDto {
  /**  */
  authToken?: string;

  /**  */
  deviceToken?: string;

  /**  */
  startTime: number;

  /**  */
  photo?: string;
}

export interface ApprovalDto {
  /**  */
  setBy: string;

  /**  */
  setByType: string;

  /**  */
  isApproved: boolean;
}

export interface RecordedAssignmentWithEmployeeNameDto {
  /**  */
  _id?: string;

  /**  */
  recordedInStructureNode: string;

  /**  */
  created?: Date;

  /**  */
  updated?: Date;

  /**  */
  employee: string;

  /**  */
  startTime?: Date;

  /**  */
  endTime?: Date;

  /**  */
  originalStartTime?: Date;

  /**  */
  originalEndTime?: Date;

  /**  */
  breakInMin?: number;

  /**  */
  updatedStartTime?: boolean;

  /**  */
  updatedEndTime?: boolean;

  /**  */
  updatedStartTimeBy?: EnumRecordedAssignmentWithEmployeeNameDtoUpdatedStartTimeBy;

  /**  */
  updatedEndTimeBy?: EnumRecordedAssignmentWithEmployeeNameDtoUpdatedEndTimeBy;

  /**  */
  autoClosed?: boolean;

  /**  */
  businessDay?: Date;

  /**  */
  approval?: ApprovalDto;

  /**  */
  group?: string;

  /**  */
  employeeName: string;
}

export interface RecordEndTimeDto {
  /**  */
  authToken?: string;

  /**  */
  deviceToken?: string;

  /**  */
  endTime: number;

  /**  */
  photo?: string;
}

export interface IncomingDevicePingDto {
  /**  */
  lastUpdatedDate?: Date;

  /**  */
  deviceToken: string;

  /**  */
  runningVersion?: string;
}

export interface DeviceConfigurationDto {
  /**  */
  takePictureWhenClockingInOrOut: boolean;

  /**  */
  pinLength: number;

  /**  */
  showBreakButtons: boolean;
}

export interface DevicePingDto {
  /**  */
  date: Date;

  /**  */
  device: string;

  /**  */
  location: string;

  /**  */
  configuration: DeviceConfigurationDto;
}

export interface LoadDeviceConfigDto {
  /** Unique identifier of the device */
  deviceId: string;

  /** StartCode given when creating a new device */
  startCode: string;

  /** android\/ios\/windows */
  os: string;
}

export interface GetDeviceConfigDto {
  /**  */
  authToken: string;

  /**  */
  structureNodeId: string;

  /**  */
  pinLength: number;

  /**  */
  takePictureWhenClockingInOrOut: boolean;

  /**  */
  showBreakButtons: boolean;

  /**  */
  location: string;
}
export enum EnumRecordedAssignmentWithEmployeeNameDtoUpdatedStartTimeBy {
  'user' = 'user',
  'system' = 'system',
  'employee-app' = 'employee-app'
}
export enum EnumRecordedAssignmentWithEmployeeNameDtoUpdatedEndTimeBy {
  'user' = 'user',
  'system' = 'system',
  'employee-app' = 'employee-app'
}
